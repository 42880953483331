<template>
  <ion-page><cyan-page-wrapper title="Elegir nivel" backRoute="/home" nav1="MODO PARTIDO">
      <div class="homeButtonContainer">
        <user-data class="cabeceraUsuario mobileShowFlex" />
        <div class="dummyDiv mobileHideBlock"></div>

        <ion-button
          expand="block"
          strong="true"
          size="large"
          @click="router.replace('/partyList/SV')"
          >CONSEJO NACIONAL</ion-button
        >

        <ion-button
          expand="block"
          strong="true"
          size="large"
          @click="router.replace(makeRoute(4))"
          >CONSEJO DEPARTAMENTAL</ion-button
        >

        <ion-button
          expand="block"
          strong="true"
          size="large"
          @click="router.replace(makeRoute(8))"
          >CONSEJO DISTRITAL</ion-button
        >
        <ion-button
          expand="block"
          strong="true"
          size="large"
          @click="router.replace(makeRoute(10))"
          >EQUIPOS DE ZONA</ion-button
        >
        <div class="dummyDiv"></div>
      </div>
  </cyan-page-wrapper></ion-page>
</template>

<script lang="ts">
import CyanPageWrapper, {
  router,
  store,
  defineComponent,
} from "@/components/CyanPageWrapper.vue";
import UserData from "@/components/UserData.vue";
import cyanRegions from "@/modules/cyanRegions";
import { IonButton,IonPage } from "@ionic/vue";

export default defineComponent({
  name: "PartyLevels",
  components: {
    IonButton,
    UserData,
    IonPage,
    CyanPageWrapper,
  },
  computed: {
    router() {
      return router;
    },

    nivel() {
      return store.state.userData.nivel || cyanRegions.base;
    }
  },
  methods: {
    makeRoute: function(length: number): string {
      if (this.nivel.length >= length) {
        return '/partyList/' + this.nivel.substring(0, length);
      } else {
        return '/partyPicker/' + length + '/' + this.nivel;
      }
    }
  },
});
</script>

<style scoped>
.rel {
  position: relative;
}
.cabeceraUsuario {
  margin-top: 2vh;
}
.homeButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  height: auto;
  bottom: 0;
  position: absolute;
  top: 0;
  left: 2vh;
  right: 2vh;
}
.dummyDiv {
  visibility: hidden;
  width: 0;
  height: 0;
}
ion-button {
  height: 10vh;
}
@media (max-height: 400px) {
  .cabeceraUsuario  {
    display: none;
  }
  .dummyDiv {
    display:block;
  }
}
</style>
