
import CyanPageWrapper, {
  router,
  store,
  defineComponent,
} from "@/components/CyanPageWrapper.vue";
import UserData from "@/components/UserData.vue";
import cyanRegions from "@/modules/cyanRegions";
import { IonButton,IonPage } from "@ionic/vue";

export default defineComponent({
  name: "PartyLevels",
  components: {
    IonButton,
    UserData,
    IonPage,
    CyanPageWrapper,
  },
  computed: {
    router() {
      return router;
    },

    nivel() {
      return store.state.userData.nivel || cyanRegions.base;
    }
  },
  methods: {
    makeRoute: function(length: number): string {
      if (this.nivel.length >= length) {
        return '/partyList/' + this.nivel.substring(0, length);
      } else {
        return '/partyPicker/' + length + '/' + this.nivel;
      }
    }
  },
});
